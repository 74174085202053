body {
   background: #f7fcf8;
   line-height: 1;
}
#root {
  height: 100%;
}
.App {
  height: 100%;
  background: #f7fcf8;;
  text-align: center;
  padding: 0;
  display: flex;
  flex-direction: column;
  position: relative;
}

.ant-input {
  height: 42px;
  border-radius: 3px;
  border: solid 1px #dfe6e0;
  background-color: #ffffff;
  font-size: 13px;
  color: #8a998e;
}
.ant-input::placeholder {
  color: #8a998e;
  opacity: 1;
}

.pill {
  line-height: 1.46;
  font-size: 13px;
  font-weight: 600;
  color: #37b34a;
  margin-right: 5px;
  border-radius: 3px;
  border: solid 1px #37b34a;
  padding: 0 10px 1px 10px;
}

.ant-popover {
  padding-left: 14px;
  padding-right: 14px;
}
.ant-popover-title {
  padding: 0;
  border: none;
}
.ant-popover-inner-content {
  padding: 0;
}
